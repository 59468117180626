<template>
  <div class="toDoctors">
    <div class="toDoctors-title">向您推荐</div>
    <div
      v-if="!fObj.isDoctorTeam && fObj.isDoctorTeam !== 0"
      class="toDoctors-b"
    >
      <div class="toDoctors-b-img">
        <img v-if="fObj.doctorHeadImg" :src="fObj.doctorHeadImg" />
        <img v-else src="../../../assets/img/bg2.png" />
      </div>
      <div style="width: calc(100% - 50px)">
        <div class="toDoctors-b-name">{{ fObj.doctorName }}</div>
      </div>
    </div>
    <div v-if="fObj.isDoctorTeam == 0" class="toDoctors-b">
      <div class="toDoctors-b-img">
        <img v-if="fObj.doctorHeadImg" :src="fObj.doctorHeadImg" />
        <img v-else src="../../../assets/img/bg2.png" />
      </div>
      <div>
        <div class="toDoctors-b-name">{{ fObj.doctorName }}</div>
        <div class="toDoctors-b-text">
          {{ fObj.departmentAliasName }} |
          {{ getZcName(fObj.doctorProfessional) }}
        </div>
      </div>
    </div>
    <div v-else-if="fObj.isDoctorTeam == 1" class="toDoctors-b">
      <div class="toDoctors-b-img">
        <img :src="fObj.teamHeadImg" />
      </div>
      <div>
        <div class="toDoctors-b-name">{{ fObj.teamName }}</div>
        <div class="toDoctors-b-text">
          {{ fObj.departmentAliasName }}
        </div>
      </div>
    </div>
    <div v-if="fObj.organizationName" class="toDoctors-c">
      <span class="toDoctors-c-text">{{ fObj.organizationName }}</span>
    </div>
    <div class="toDoctors-d">
      <span @click="lookDoctorInfo">立即查看</span>
    </div>
  </div>
  <van-popup
    style="background-color: #53a5ff"
    v-model:show="isShowPopup"
    closeable
    round
    :close-on-click-overlay="false"
    :style="{ height: '70%', width: '80%' }"
  >
    <div class="content-box">
      <!-- 医生 团队 -->
      <doctor-detail
        v-if="(isShowPopup && fObj.isDoctorTeam == 0) || !fObj.isDoctorTeam"
        :docId="fObj.id"
      ></doctor-detail>
      <doctor-detail
        v-if="isShowPopup && fObj.isDoctorTeam == 1"
        :docId="fObj.leaderId"
      ></doctor-detail>
    </div>
  </van-popup>
</template>
  
  <script>
import { Popup } from "vant";
import doctorDetail from "../../../views/doctorDetail.vue";
export default {
  data() {
    return {
      fObj: {},
      isShowPopup: false,
    };
  },
  components: {
    [Popup.name]: Popup,
    doctorDetail,
  },
  props: ["content"],
  watch: {},
  created() {
    if (this.content) {
      if (typeof this.content == "object") {
        this.fObj = this.content;
      } else {
        try {
          this.fObj = JSON.parse(this.content);
        } catch {
          this.fObj = {};
        }
      }
    } else {
      this.fObj = {};
    }
  },
  methods: {
    lookDoctorInfo() {
      this.isShowPopup = true;
    },
    getZcName(id) {
      if (!id) {
        return "";
      }
      let flag = true;
      let list = this.$store.state.jobTitleList;
      for (let i in list) {
        if (list[i].id == id) {
          flag = false;
          return list[i].lable;
        }
      }
      if (flag) {
        return id;
      }
      return "";
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.toDoctors {
  padding: 10px;
  background: #ffffff;
  border-radius: 0px 8px 8px 8px;
  .toDoctors-title {
    font-size: 15px;
    font-weight: 500;
    color: #272d3b;
    line-height: 30px;
  }
  .toDoctors-b {
    display: flex;
    justify-content: start;
    .toDoctors-b-img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .toDoctors-b-name {
      font-size: 16px;
      font-weight: 500;
      color: #272d3b;
      line-height: 22px;
    }
    .toDoctors-b-text {
      font-size: 14px;
      font-weight: 400;
      color: #272d3b;
      line-height: 20px;
    }
  }
  .toDoctors-c {
    padding: 7px 0 0 13px;
    .toDoctors-c-text {
      font-size: 12px;
      font-weight: 400;
      background-color: #eaf4ff;
      color: #278eff;
      line-height: 17px;
      padding: 2px 4px;
    }
  }
  .toDoctors-d {
    margin-top: 11px;
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    text-align: center;
    > span {
      font-size: 14px;
      font-weight: 400;
      color: #278eff;
    }
  }
}
.van-popup--center.van-popup--round {
  background-color: #278eff;
}
.content-box {
  margin-top: 45px;
  height: calc(100% - 45px);
  background-color: #fff;
  overflow: auto;
}
</style>