<template>
  <div @click="toRoom" class="toDoctors">
    <div class="toDoctors-title">已为您预约成功</div>
    <div class="toDoctors-b">
      <div class="toDoctors-b-img">
        <img v-if="fObj.doctorHeadImg" :src="fObj.doctorHeadImg" />
        <img v-else src="../../../assets/img/bg2.png" />
      </div>
      <div class="toDoctors-b-r">
        <div class="toDoctors-b-name">{{ fObj.doctorName }}</div>
        <div v-if="fObj.doctorId" class="toDoctors-b-text">
          {{ fObj.departmentAliasName }} |
          {{ getZcName(fObj.doctorProfessional) }}
        </div>
        <div v-else class="toDoctors-b-text">
          {{ fObj.departmentAliasName }}
        </div>
      </div>
    </div>
    <div class="toDoctors-c">
      <span class="toDoctors-c-text">{{ fObj.organizationName }}</span>
    </div>
    <div class="toDoctors-e" style="text-align: left">
      <span style="color: #888; font-size: 12px">时间：</span>
      <span style="color: #f79c42; font-size: 14px; text-align: center">{{
        fObj.startConsultTime
      }}</span>
    </div>
    <div class="toDoctors-e" style="text-align: left">
      <span style="color: #888; font-size: 12px">会诊时长：</span>
      <span style="color: #f79c42; font-size: 14px">{{
        fObj.consultationDuration
      }}</span>
    </div>

    <div class="toDoctors-e" style="text-align: left">
      <span style="color: #888; font-size: 12px">服务类型：</span>
      <span class="severType1 severType" v-if="fObj.serverType == 1">图文</span>
      <span class="severType2 severType" v-else-if="fObj.serverType == 2"
        >语音</span
      >
      <span class="severType3 severType" v-else-if="fObj.serverType == 3"
        >视频</span
      >
      <span v-else></span>
    </div>
  </div>
</template>

  <script>
export default {
  data() {
    return {
      fObj: {},
    };
  },
  props: ["content"],
  watch: {},
  created() {
    if (this.content) {
      if (typeof this.content == "object") {
        this.fObj = this.content;
      } else {
        try {
          this.fObj = JSON.parse(this.content);
        } catch {
          this.fObj = {};
        }
      }
    } else {
      this.fObj = {};
    }
    if (this.fObj.consultationDuration) {
      if (!isNaN(this.fObj.consultationDuration)) {
        let s = Number(this.fObj.consultationDuration);
        this.fObj.consultationDuration =
          (s - (s % 60)) / 60 + "小时" + (s % 60) + "分钟";
      }
    }
  },
  methods: {
    getZcName(id) {
      if (!id) {
        return "";
      }
      let flag = true;
      let list = this.$store.state.jobTitleList;
      for (let i in list) {
        if (list[i].id == id) {
          flag = false;
          return list[i].lable;
        }
      }
      if (flag) {
        return id;
      }
      return "";
    },
    // 跳转会诊聊天室
    toRoom() {
      if (this.fObj.roomId) {
        this.$router.push({
          path: "/chatRoom",
          query: {
            seeDoctorId: this.fObj.seeDoctorId,
            roomId: this.fObj.roomId,
          },
        });
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.toDoctors {
  padding: 10px;
  background: #ffffff;
  border-radius: 0px 8px 8px 8px;
  .toDoctors-title {
    font-size: 15px;
    font-weight: 500;
    color: #272d3b;
    line-height: 30px;
  }
  .toDoctors-b {
    display: flex;
    justify-content: start;
    .toDoctors-b-img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .toDoctors-b-r {
      width: calc(100% - 56px);
    }
    .toDoctors-b-name {
      font-size: 16px;
      font-weight: 500;
      color: #272d3b;
      line-height: 22px;
    }
    .toDoctors-b-text {
      font-size: 14px;
      font-weight: 400;
      color: #272d3b;
      line-height: 20px;
    }
  }
  .toDoctors-c {
    padding: 7px 0 11px 13px;
    .toDoctors-c-text {
      font-size: 12px;
      font-weight: 400;
      background-color: #eaf4ff;
      color: #278eff;
      line-height: 17px;
      padding: 2px 4px;
    }
  }
  .toDoctors-d {
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    text-align: center;
  }
  .toDoctors-e {
    line-height: 24px;
  }
  .severType {
    font-size: 14px;
  }
  .severType1 {
    color: #278eff;
  }
  .severType2 {
    color: #2ac2a8;
  }
  .severType3 {
    color: #f79c42;
  }
}
</style>