<template>
    <div class="unpaid">
    	<div class="unpaid-title">您已支付成功！</div>
    	<div class="unpaid-b">预约医生{{fObj.isDoctorTeam==1?'团队':''}}：{{fObj.doctorName}}</div>
    	<div class="unpaid-c">服务费用：<span style="color: #FF1E1E;">{{getServerPrice(fObj.serverPrice)}}元</span> </div>
    </div>
  </template>
  
  <script>
  export default {
	  data() {
	  	return {
			fObj:{},
		}
	  },
	  props:["content",'disabled'],
	  watch:{},
	  created() {
	  	if(this.content){
			if(typeof this.content=="object"){
				this.fObj=this.content
			}else{
				try{
					this.fObj=JSON.parse(this.content)
				}catch{
					this.fObj={}
				}
			}
		}else{
			this.fObj={}
		}
	  },
	  methods:{
		getServerPrice(n){
			if(!n){
				return n
			}
			n=n/100;
			return Number(n.toFixed(2))
		}
	  }
  }
  </script>
  
  <style lang="scss" scoped>
	  .unpaid{
	  	width: 240px;
	  	padding: 10px;
	  	background: #FFFFFF;
	  	border-radius: 0px 8px 8px 8px;
	  	.unpaid-title{
	  		font-size: 15px;
	  		font-weight: 500;
	  		color: #272D3B;
	  		line-height: 30px;
	  		
	  	}
	  	.unpaid-b,.unpaid-c{
	  		font-size: 13px;
	  		line-height: 24px;
	  		color: #272D3B;
	  	}
	  	
	  }
  </style>