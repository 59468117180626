<template>
    <div class="unpaid">
    	<div v-if="dingdanList['d_'+fObj.id] == 0" class="unpaid-title">您有一笔待支付订单</div>
    	<div v-else-if="dingdanList['d_'+fObj.id] == 1||dingdanList['d_'+fObj.id] == 4||dingdanList['d_'+fObj.id] == 5" class="unpaid-title">您的订单已支付</div>
    	<div v-else-if="dingdanList['d_'+fObj.id] == 2" class="unpaid-title">您有一笔订单已关闭</div>
		<div class="unpaid-title" v-else>&nbsp;</div>
    	<div class="unpaid-b">预约医生：{{fObj.doctorName}}</div>
    	<div class="unpaid-c">服务费用：<span style="color: #FF1E1E;">{{getServerPrice(fObj.serverPrice)}}元</span> </div>
		<div class="unpaid-c">服务类型：<span class=" severType" v-if="fObj.serverType == 1">图文</span>
		 <span class=" severType" v-else-if="fObj.serverType == 2" >语音</span >
		 <span class=" severType" v-else-if="fObj.serverType == 3" >视频</span >
		 <span v-else>&nbsp;</span>
		 </div>
		
		
    	<div class="unpaid-d" v-show="dingdanList['d_'+fObj.id] == 0">
    		<span @click="yzf">立即支付</span>
    	</div>
		<div class="unpaid-d zh" v-show="dingdanList['d_'+fObj.id] != 0" >
			<span>立即支付</span>
		</div>
		<!-- 授权弹框 -->
		<van-popup v-model:show="isShow.hzbsq" position="top" :style="{ height: '70vh' }" >
			<div style="height:90%">
				<iframe v-if="isShow.hzbsq" :src="iframeSrc" width="100%" height="100%" ></iframe>
			</div>
		</van-popup>
    </div>
  </template>
  
  <script>
import {Toast,Popup} from "vant"
  export default {
	  data() {
	  	return {
			fObj:{},
			isLoading:{
				zf:false,
			},
			isShow:{
				hzbsq:false,
			},
			iframeSrc:""
		}
	  },
	  components:{
		[Popup.name]:Popup,  
	  },
	  props:["content","dingdanList"],
	  watch:{
		  "$store.state.changeDingdanObj"(item){
			  console.log(item,"订单支付-------------");
			  if(item.msgContent){
				  try{
					  let m=JSON.parse(item.msgContent)
					  if(m.orderId==this.fObj.id){
						  this.dingdanList['d_'+this.fObj.id]=m.orderStatus
					  }
				  }catch{}
			  }
		  }
	  },
	  created() {
	  	if(this.content){
			if(typeof this.content=="object"){
				this.fObj=this.content
			}else{
				try{
					this.fObj=JSON.parse(this.content)
				}catch{
					this.fObj={}
				}
			}
		}else{
			this.fObj={}
		}
		if(!this.dingdanList['d_'+this.fObj.id]){
			this.getOrderOrderStatus()
		}
	  },
	  methods:{
		  getZcName(id){
			if(!id){
				return ""
			}
			let flag = true
			let list=this.$store.state.jobTitleList;
			for(let i in list){
				if(list[i].id==id){
					flag = false
					return list[i].lable
				}
			}
			if(flag){
				return id
			}
			return ""
		},
		// 点击支付
		yzf(){
			if(this.isLoading.zf){
				Toast("正在支付中...")
				return;
			}
			this.isLoading.zf=true
			
			this.weChatParameter={};
			this.$http.request("getOrderJSAPIPrepay",{id:this.fObj.id})
			.then((res)=>{
				if(res&&res.appId){
					this.weChatParameter=res;
					this.weixinPay()
				}else{
			   let iframeSrc =window.location.origin+"/"+process.env.BASE_URL +"hzbLoginPage.html?uid=" +
							this.$cache.local.getItem("patientId")
							+"&returnUrl="+window.encodeURIComponent(window.location.href);
				window.location.replace(iframeSrc);
		  }
			})
			.catch(()=>{
				this.isLoading.zf=false;
			})
		},
		
		//解决微信内置对象报错
		weixinPay() {
			let instance = Toast("请稍等...");
			var that = this;
			if (typeof WeixinJSBridge == 'undefined') {
				if (document.addEventListener) {
					document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(instance), false);
				} else if (document.attachEvent) {
					document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(instance));
					document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(instance));
				}
			} else {
				that.onBridgeReady(instance);
			}
		},
		//微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
		onBridgeReady(instance) {
			var that = this;
			console.error("支付前")
			WeixinJSBridge.invoke(
				'getBrandWCPayRequest',
				{
					appId: that.weChatParameter.appId, //公众号名称，由商户传入
					timeStamp: that.weChatParameter.timeStamp, //时间戳，自1970年以来的秒数
					nonceStr: that.weChatParameter.noticeStr, //随机串
					package: that.weChatParameter.prepayId,
					signType: 'RSA', //微信签名方式：RSA
					paySign: that.weChatParameter.sign //微信签名
					// jsApiList: ['chooseWXPay']
				},
				function(res) {
					console.error(res)
					// 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
					if (res.err_msg == 'get_brand_wcpay_request:ok') {
						that.dingdanList['d_'+that.fObj.id]=1
						Toast.success("支付成功");
					} else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
						//取消支付的操作
						instance.close();
						that.isLoading.zf=false;
					} else {
						//支付失败的操作
						instance.close();
						Toast("支付失败");
						that.isLoading.zf=false;
					}
				}
			);
		},
		// 价格转换
		getServerPrice(n){
			if(!n){
				return n
			}
			n=n/100;
			return Number(n.toFixed(2))
		},
		// 获取支付状态
		getOrderOrderStatus(){
			this.$http.request("getOrderOrderStatus",{id:this.fObj.id})
			.then((res)=>{
				this.dingdanList['d_'+this.fObj.id]=res.orderStatus
			})
			.catch(()=>{})
		},
		
	  }
  }
  </script>
  
  <style lang="scss" scoped>
	  .unpaid{
	  	padding: 0 6px;
	  	background: #FFFFFF;
	  	border-radius: 0px 8px 8px 8px;
	  	.unpaid-title{
	  		font-size: 15px;
	  		font-weight: 500;
	  		color: #272D3B;
	  		line-height: 30px;
	  		
	  	}
	  	.unpaid-b,.unpaid-c{
	  		font-size: 13px;
	  		line-height: 24px;
	  		color: #272D3B;
	  	}
	  	.unpaid-d{
	  		margin-top: 6px;
	  		border-top: 1px solid #eeeeee;
	  		padding-top: 10px;
	  		text-align: center;
	  		>span{
	  			font-size: 14px;
	  			font-weight: 400;
	  			color: #278EFF;
	  		}
			&.zh{
				>span{
					color: #999;
				}
			}
	  	}
	  }
	  .severType {
	      // font-size: 14px;
	    }
	    .severType1 {
	      color: #278eff;
	    }
	    .severType2 {
	      color: #2ac2a8;
	    }
	    .severType3 {
	      color: #f79c42;
	    }
  </style>