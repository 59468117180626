<template>
    <div>
    	<div>{{fObj.text}}</div>
		<div style="text-align: right;margin-top: 6px;">
			<van-button plain hairline    @click="isShow.jzrInfo=true" text type="primary" size="small" >详情 >> </van-button>
		</div>
		
		<!-- 就诊人信息 -->
		<van-popup
		  v-model:show="isShow.jzrInfo"
		  get-container="body"
		  position="bottom"
		>
		  <div
		    style="
		      display: flex;
		      justify-content: flex-end;
		      line-height: 32px;
		      color: #00a8ff;
		      padding: 6px 16px;
		      font-size: 16px;
		    "
		  >
		    <span @click="isShow.jzrInfo = false">关闭</span>
		  </div>
		  <jzr-info
			type="1"
		    :seeDoctorId="fObj.id"
		    v-model:show="isShow.jzrInfo"
		  ></jzr-info>
		</van-popup>
		
    </div>
  </template>
  
  <script>
	  import {Button,Popup} from "vant"
	  import jzrInfo from "./jzrInfo.vue"
  export default {
	  data() {
	  	return {
			fObj:{},
			isShow:{
				jzrInfo:false,
			}
			
		}
	  },
	  components:{
		  [Button.name]:Button,
		  [Popup.name]:Popup,
		  jzrInfo,
	  },
	  props:["content"],
	  watch:{},
	  created() {
	  	if(this.content){
			if(typeof this.content=="object"){
				this.fObj=this.content
			}else{
				try{
					this.fObj=JSON.parse(this.content)
				}catch{
					this.fObj={}
				}
			}
			let ctext="";
			if(this.fObj.conditionContent){
				if(this.fObj.conditionContent.length>10){
					ctext=this.fObj.conditionContent.slice(0,10)+"..."
				}else {
					ctext=this.fObj.conditionContent;
				}
			}
			this.fObj.text=`您好，我想咨询:${ctext}的问题,（${this.fObj.name},${this.fObj.age}岁,${this.fObj.sex==2?'女':'男'})`
		}else{
			this.fObj={}
		}
	  },
	  methods:{}
  }
  </script>
  
  <style lang="scss" scoped>
	  
  </style>