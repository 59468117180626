<template>
  <!-- 聊天室 --患者/医助 -->
  <div class="ltsPage">
    <div class="dw-top">
      <van-notice-bar
        wrapable
        v-if="isShow.fixedTop"
        :scrollable="false"
        mode="closeable"
      >
        由于疾病有演变过程，如出现情况变化或不适，请您及时去线下医院检查就诊。
      </van-notice-bar>
      <div
        v-if="topObj && topObj.type == 1"
        @click="showSpYy('yy')"
        class="bot-msg bot-msg-vol1"
      >
        <span> 当前语音通话等待接听 </span>
        <span class="zixun">
          接听
          <img src="../assets/img/lft2-white.png" alt="" style="width: 20px" />
        </span>
      </div>
      <div
        v-else-if="topObj && topObj.type == 2"
        @click="showSpYy('sp')"
        class="bot-msg bot-msg-vol2"
      >
        <span> 当前视频通话等待接听 </span>
        <span class="zixun">
          接听
          <img src="../assets/img/lft2-white.png" alt="" style="width: 20px" />
        </span>
      </div>
    </div>

    <van-pull-refresh
      v-model="isLoading.refresh"
      success-text="加载成功"
      @refresh="onRefresh"
      ref="listView"
      class="view"
      @click.native="isShow.elseFeature = false"
    >
      <div ref="list" class="list">
        <template v-for="item in list">
          <!-- 患者（本人）文字消息 -->
          <!-- 医助文字消息 -->
          <div class="tsText item" v-if="item.msgType == 9">
            {{ item.content || "" }}
          </div>
          <div v-else-if="item.msgType!=10" class="item">
            <div class="my" v-if="item.sendType == 2">
              <div class="textAndName">
                <div class="createTime">{{ item.createTime || "" }}</div>
                <div class="userName">{{ item.name || getName(item) }}</div>
                <div class="text" :class="item.msgType == 1 ? 'text-bg' : ''">
                  <span style="white-space: pre-line;" v-if="item.msgType == 1">
                    {{ item.content || "" }}
                  </span>
                  <img
                    class="wsImg"
                    @click="showbigImg([item.content])"
                    v-else-if="item.msgType == 2"
                    :src="item.content"
                  />
                  <!-- 推荐医生 -->
                  <div class="toDoctors-view" v-else-if="item.msgType == 5">
                    <tuijian-yisheng :content="item.content"></tuijian-yisheng>
                  </div>
                  <!-- 文件 -->
                  <!-- <a
                    target="_blank"
                    :href="item.content"
                    class=""
                    v-else-if="item.msgType == 6"
                  >
                    <img src="../assets/img/icon/wenjian.png" />
                  </a> -->
                  <div v-else-if="item.msgType == 6">
                    <div
                      v-if="
                        ['mp4', 'rmvb', 'avi', 'ts'].includes(item.fileTypeVal)
                      "
                    >
                      <video
                        @play="controlSingleVideo"
                        :src="item.fileUrl"
                        style="height: 200px; width: 100%"
                        controls="controls"
                      ></video>
                    </div>
                    <span
                      v-else
						@click="loadFile(item.fileUrl,item.fileName || item.fileUrl)"
                      class="download-box"
                    >
                      <p class="file-name">
                        <span>{{ item.fileName || item.fileUrl }}</span>
                      </p>
                      <img src="../assets/img/icon/wenjian.png" />
                    </span>
                  </div>
                  <div class="unpaid-view" v-else-if="item.msgType == 11">
                    <zhifu-chenggong :content="item.content"></zhifu-chenggong>
                  </div>
				  <div style="float: left" v-if="item.msgType == 13">
				    <bingqing-card class="text" :content="item.content"></bingqing-card>
				  </div>
                </div>
              </div>
              <div class="headIcon">
                <img :src="item.img || getHeadIcon(item)" />
              </div>
            </div>
            <div v-else class="you">
              <div class="headIcon">
                <img :src="item.img || getHeadIcon(item)" />
              </div>
              <div class="textAndName">
                <div class="createTime">{{ item.createTime || "" }}</div>
                <div class="userName">{{ item.name || getName(item) }}</div>
                <div class="text">
                  <span style="white-space: pre-line;" v-if="item.msgType == 1">
                    {{ item.content || "" }}
                  </span>
                  <img
                    class="wsImg"
                    @click="showbigImg([item.content])"
                    v-else-if="item.msgType == 2"
                    :src="item.content"
                  />

                  <!-- 待支付 -->
                  <div class="unpaid-view" v-else-if="item.msgType == 3">
                    <dai-zifu
                      :dingdanList="dingdanList"
                      :content="item.content"
                    ></dai-zifu>
                  </div>
                  <!-- 成功预约 -->
                  <div class="toDoctors-view" v-else-if="item.msgType == 4">
                    <yuyue-chenggong :content="item.content"></yuyue-chenggong>
                  </div>
                  <!-- 推荐医生 -->
                  <div class="toDoctors-view" v-else-if="item.msgType == 5">
                    <tuijian-yisheng :content="item.content"></tuijian-yisheng>
                  </div>
                  <!-- 文件 -->
                  <!-- <a
                    target="_blank"
                    :href="item.content"
                    class=""
                    v-else-if="item.msgType == 6"
                  >
                    <img src="../assets/img/icon/wenjian.png" />
                  </a> -->
                  <div v-else-if="item.msgType == 6">
                    <div
                      v-if="
                        ['mp4', 'rmvb', 'avi', 'ts'].includes(item.fileTypeVal)
                      "
                    >
                      <video
                        @play="controlSingleVideo"
                        :src="item.fileUrl"
                        style="height: 200px; width: 100%"
                        controls="controls"
                      ></video>
                    </div>
                    <span
                      v-else
                      @click="loadFile(item.fileUrl,item.fileName || item.fileUrl)"
                      class="download-box"
                    >
                      <p class="file-name">
                        <span>{{ item.fileName || item.fileUrl }}</span>
                      </p>
                      <img src="../assets/img/icon/wenjian.png" />
                    </span>
                  </div>
                  <!--  -->
                  <!-- 7视频 -->
                  <div class="item-view7" v-else-if="item.msgType == 7">
                    <div class="view-z">
                      <div class="item-title">您有视频通话待接听</div>
                      <div class="item-bottom">
                        <span @click="showSpYy('sp')">接听</span>
                      </div>
                    </div>
                  </div>
                  <!-- 8语音 -->
                  <div class="item-view8" v-else-if="item.msgType == 8">
                    <div class="view-z">
                      <div class="item-title">您有语音通话待接听</div>
                      <div class="item-bottom">
                        <span @click="showSpYy('yy')">接听</span>
                      </div>
                    </div>
                  </div>
                  <!-- 10为即时消息 ws发送时 无内容显示 -->
                  <!-- 11 支付成功 -->
                  <div class="unpaid-view" v-else-if="item.msgType == 11">
                    <zhifu-chenggong :content="item.content"></zhifu-chenggong>
                  </div>
				  <div style="float: left" v-if="item.msgType == 13">
				    <bingqing-card class="text" :content="item.content"></bingqing-card>
				  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </van-pull-refresh>
    <div
      class="bottomView"
	  ref="bottomView"
    >
      <div>
        <div class="bottomView-tob2" >
          <!-- <VoiceAdd><img src="../assets/img/icon/huatong.png" /></VoiceAdd> -->
          <!-- <input @focus="isShow.elseFeature = false" v-model="inputText" /> -->
		  <!-- <textarea :autoHeight="true"  @focus="isShow.elseFeature = false" v-model="inputText"></textarea> -->
		  <!-- <van-cell-group> -->
		    <van-field
		      v-model="inputText"
		      rows="1"
			  size="large"
		      :autosize=" { maxHeight: 200, minHeight: 30 }"
		      label=""
			  @focus="hideElseFeature"
		      type="textarea"
			  class="inputText"
		      placeholder="请输入"
		    >
			<!-- extra -->
			<template #extra>
			    <div >
					<div style="height: 36px;overflow: hidden;margin-left: 6px;">
						<van-button
						 style="height: 32px;line-height: 32px;"
						 v-show="inputText" size="small" type="primary" @click="sendText" >发送</van-button>
						<img
						v-show="!inputText"
						  @click="changeElseFeature"
						  src="../assets/img/icon/gengduo.png"
						/>
					</div>
				</div>
			    </template>
			</van-field>
			<!-- </van-cell-group> -->
        </div>
      </div>
      <!-- 其他功能显示 -->
      <div v-show="isShow.elseFeature" ref="elseFeature" class="elseFeature">
        <div class="elseFeature-item">
          <div>
            <label>
              <input
                type="file"
                @change="sendImg"
                accept="image/*"
                style="display: none"
              />
              <img src="../assets/img/icon/xiangce.png" />
            </label>
          </div>
          <p>相册</p>
        </div>
        <div v-show="!isShow.sp" class="elseFeature-item">
          <div>
            <photograph :activeUser="activeUser" @onImgDate="sendImg_ps"
              ><img src="../assets/img/icon/baishe.png"
            /></photograph>
          </div>
          <p>拍摄</p>
        </div>
		<!-- <div @click="$myws.close" >手动断开</div>
		<div @click="$myws.initMyWs(42)">连接</div> -->
        <!-- <div @click="showSpYy('sp')" class="elseFeature-item">
          <div><img src="../assets/img/icon/shiping.png" /></div>
          <p>视频</p>
        </div>
        <div @click="showSpYy('yy')" class="elseFeature-item">
          <div><img src="../assets/img/icon/yuyin.png" /></div>
          <p>语音</p>
        </div> -->
        <div class="elseFeature-item">
          <div>
            <label>
              <input type="file" @change="sendFile" style="display: none" />
              <img src="../assets/img/icon/wenjian.png" />
            </label>
          </div>
          <p>文件</p>
        </div>
        <div @click="showJzrInfo" class="elseFeature-item">
          <div><img src="../assets/img/icon/pat.png" /></div>
          <p>患者信息</p>
        </div>
      </div>
    </div>

    <!-- 视频聊天 -->
    <video-conferencing
	ref="sp"
      v-show="isShow.spView"
      v-model:show="isShow.sp"
      :activeUser="activeUser"
	   :userObj="userObj"
      :userId="userId"
    ></video-conferencing>
    <!-- 语音聊天 -->
    <audio-conferencing
	ref="yy"
      v-show="isShow.yyView"
      v-model:show="isShow.yy"
      :activeUser="activeUser"
	  :userObj="userObj"
      :userId="userId"
    ></audio-conferencing>
    <!-- 视频/音频隐藏时的按钮 -->
    <div class="fk" v-show="isShow.sp || isShow.yy">
      <div @click="isShow.spView = !isShow.spView" v-show="isShow.sp">
        视频{{ isShow.spView ? "隐藏" : "显示" }}
      </div>
      <div @click="isShow.yyView = !isShow.yyView" v-show="isShow.yy">
        音频{{ isShow.yyView ? "隐藏" : "显示" }}
      </div>
    </div>

    <!-- 就诊人信息 -->
    <van-popup
      v-model:show="isShow.hzInfo"
      get-container="body"
      position="bottom"
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          line-height: 32px;
          color: #00a8ff;
          padding: 6px 16px;
          font-size: 16px;
        "
      >
        <span @click="isShow.hzInfo = false">关闭</span>
      </div>
      <jzr-info
		type="1"
        :seeDoctorId="$route.query.seeDoctorId"
        v-model:show="isShow.hzInfo"
      ></jzr-info>
    </van-popup>
  </div>
</template>

<script>
import { Form, Toast, PullRefresh, ImagePreview, Popup, NoticeBar,Button,Dialog,CellGroup,Field } from "vant";
import VoiceAdd from "../components/voice/VoiceAdd.vue";
import photograph from "../components/img/photograph.vue";
import videoConferencing from "../components/compRoom/voice/videoConferencing";
import audioConferencing from "../components/compRoom/audio/audioConferencing";
// 待支付
import daiZifu from "../components/compRoom/mokuai/daizhifu.vue";
// 支付成功
import zhifuChenggong from "../components/compRoom/mokuai/zhifuChenggong.vue";
// 推荐医生
import tuijianYisheng from "../components/compRoom/mokuai/tuijianYisheng.vue";
// 预约成功
import yuyueChenggong from "../components/compRoom/mokuai/yuyueChenggong.vue";
// 病情概述卡片
import bingqingCard from "../components/compRoom/mokuai/bingqingCard.vue";

import jzrInfo from "../components/compRoom/mokuai/jzrInfo.vue";
// 图片压缩
import lossyImg from "../mixins/lossyImg.js";
const yizhuImg = require("../assets/img/icon/yizhu1.png");
const huanzheImg = require("../assets/img/icon/huanzhe.png");
export default {
  name: "huiZenInfo",
  components: {
    VoiceAdd,
    photograph,
    videoConferencing,
    audioConferencing,
    daiZifu,
    zhifuChenggong,
    tuijianYisheng,
    yuyueChenggong,
    jzrInfo,
	bingqingCard,
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
	[Button.name]:Button,
	[CellGroup.name]:CellGroup,
	[Field.name]:Field,
  },
  mixins: [lossyImg],
  provide() {
    return {
      sendVARoomState: this.sendVARoomState,
    };
  },
  data() {
    return {
      list: [],
      inputText: "",
      isShow: {
        elseFeature: false,
        sp: false,
        yy: false,
        spView: false,
        yyView: false,
        hzInfo: false,
        fixedTop: false,
      },
      isLoading: {
        refresh: false,
      },
      //患者、医助 头像、称呼
      userObj: {},
      userId: "",
      activeUser: {},
      // 语音视频连接状态集合
      mediaStateList: {},
      // 上方显示 --通话提醒
      topObj: null,
      getMediaStateDs: null,
      // 支付状态集合
      dingdanList: {},
      // 头部弹框定时实例
      fixedTopDs: null,
	  roomId: this.$route.query.id,
    };
  },
  created() {
    console.log("进入聊天室");
    this.userId =this.$route.query.seeDoctorId   //this.$cache.local.getItem("patientId");
    this.activeUser = {
      roomId: this.$route.query.id,
    };
	if(this.roomId){
		this.inputText=this.$cache.getRoomInputValue(this.roomId)
	}
    // webSocket--为全局唯一
    // getRoomUserList
    // 获取聊天室所有成员
    this.getuserList(this.$route.query.num, "init");
    // 模拟测试
    // window.setTimeout(()=>{this.getList(0)},2000)
    this.getMediaState();
    if (this.getMediaStateDs) {
      window.clearInterval(this.getMediaStateDs);
    }
    this.getMediaStateDs = window.setInterval(() => {
      this.getMediaState();
    }, 10000);
    this.fixedTopDs = window.setTimeout(() => {
      this.isShow.fixedTop = true;
    }, 5 * 60 * 1000);
  },
  mounted() {},
  watch: {
    "$store.state.wsMsgNum"(item) {
      if (
        this.$store.state.wsNewMsg.roomId ==
        this.$route.query.id
      ) {
        this.setPatientCurrSessionRead();
        this.gddb();
        let newMsg = this.$store.state.wsNewMsg;
        if (newMsg.msgType == 10) {
			console.error(newMsg)
          let msgContent = {};
          try {
            msgContent = JSON.parse(newMsg.msgContent);
          } catch {}
          // 转单 -页面自跳转
          // Toast("已为您转单")
		  console.error(msgContent)
          Toast({
            message: "已为您转单",
            // position: 'top',
            duration: 2000,
          });
		  if(this.$route.query.id!=msgContent.roomId){
			  window.setTimeout(() => {
			    this.$router.replace({
			      path: "/yizhuChatRoom",
			      query: {
			        seeDoctorId: this.$route.query.seeDoctorId,
			        id: msgContent.roomId,
			      },
			    });
			  }, 2000);
		  }else{
			  this.getuserList(0,"getUserList")
		  }
        }
      }
    },
    $route(i) {
      console.log("路由变化");
      // 初始化
      this.userId = this.$cache.local.getItem("patientId");
      this.activeUser = {
        roomId: this.$route.query.id,
      };
      this.list = [];
      this.userObj = {};
      this.getuserList(this.$route.query.num, "init");
    },
    "$store.state.mediaState"(item) {
      if (item.roomId == this.$route.query.id) {
        this.setMediaState(item);
      }
    },
  },
  methods: {
    // 控制同一页面只有一个音频或视频在播放
    controlSingleVideo() {
      var videoList = document.getElementsByTagName("video");
      console.log(videoList, "videoList");
      for (let i = 0; i < videoList.length; i++) {
        let video = videoList[i];
        video.addEventListener("play", function (e) {
          console.log(video);
          console.log("提示该视频正在播放中");
          for (let j = 0; j < videoList.length; j++) {
            if (j !== i) videoList[j].pause();
          }
          for (let j = 0; j < audioList.length; j++) {
            audioList[j].pause();
          }
        });
      }
    },
    // 获取记录
    getList(limit = 20, t) {
      console.log(t);
      if (limit > 100) {
        limit = 100;
      } else if (limit > 20) {
        limit = limit;
      } else {
        limit = 20;
      }
      let f = {
        roomId: this.$route.query.id,
        limit: limit,
        offset: 0,
      };
      let wornLh = 0;
      if (t == "init") {
        this.list = [];
      } else {
        try {
          wornLh = this.$refs.list.offsetHeight;
        } catch {}
      }
      // id
      if (this.list[0] && this.list[0].id) {
        f.id = this.list[0].id;
      }
      this.$http
        .request("getChatRoomMsgList", f)
        .then((res) => {
          this.isLoading.refresh = false;
          this.setPatientCurrSessionRead();
          let list = res || [];

          if (list.length == 0) {
            Toast("没有更多信息了");
            // 重置
            this.$store.commit("resetWsMsgList", {
              roomId: this.$route.query.id,
              list: this.list,
            });
            return;
          }
          for (let i = 0; i < list.length; i++) {
            if (list[i].msgType == 6) {
              list[i].fileUrl = list[i].content.split(",")[0];
              list[i].fileName = list[i].content.split(",")[1];
              //   获取最后一个"."之后的内容
              let index = list[i].fileUrl.lastIndexOf(".");
              list[i].fileTypeVal = list[i].fileUrl.substring(
                index + 1,
                list[i].fileUrl.length
              );
            }
            list[i].img = this.getHeadIcon(list[i]);
            list[i].name = this.getName(list[i]);
            this.list.unshift(list[i]);
          }
          // console.log(this.list, "555555555");
          // 对消息列表中的数据进行浅拷贝至vuex中
          // 重置
          this.$store.commit("resetWsMsgList", {
            roomId: this.$route.query.id,
            list: this.list,
          });
          if (t == "init") {
            this.$nextTick(() => {
              console.log("滚动到最下方");
              if (this.list.length > 0) {
                let sh = this.$refs.listView.$el.offsetHeight;
                let lh = this.$refs.list.offsetHeight;
                if (lh > sh) {
                  this.$refs.listView.$el.scrollTop = lh - sh;
                  console.log(lh, sh, lh - sh);
                }
              } else {
                console.error("空");
              }
            });
          } else {
            this.$nextTick(() => {
              console.log("滚动到加载前的第一条数据");
              let lh = this.$refs.list.offsetHeight;
              this.$refs.listView.$el.scrollTop = lh - wornLh;
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    // 发送文字
    sendText() {
		
      if (!this.inputText) {
        return;
      }
      console.log("发送ws信息：" + this.inputText);
      let f = {
        roomId: this.$route.query.id,
        seeDoctorId: this.$route.query.seeDoctorId,
        // assistantConsultId: this.$route.query.id,
        // assistantSessionId: this.$route.query.assistantSessionId,
        content: this.inputText,
        msgType: 1,
        patientId: this.$cache.local.getItem("patientId"),
        sendType: 2,
      };
	  this.inputText = "";
      this.$http
        .request("sendRoomMsg", f)
        .then((res) => {
			f.id=res.id;
          console.log("发送成功");
          f.createTime = res.createTime;
          this.list.push(f);
          this.inputText = "";
          this.gddb();
        })
        .catch(() => {
			this.$nextTick(()=>{
				this.inputText = f.content;
			})
			
		});
    },
    // 发送图片、文件地址
    sendFileUrl(t, u, name) {
      let str = "";
      if (t == 6 && name) {
        str = u + "," + name;
      } else {
        str = u;
      }
      let f = {
        roomId: this.$route.query.id,
        seeDoctorId: this.$route.query.seeDoctorId,
        // assistantConsultId: this.$route.query.id,
        // assistantSessionId: this.$route.query.assistantSessionId,
        content: str,
        msgType: t,
        patientId: this.$cache.local.getItem("patientId"),
        sendType: 2,
      };
      this.$http
        .request("sendRoomMsg", f)
        .then((res) => {
			f.id=res.id;
			f.createTime = res.createTime;
          console.log("发送成功");
          if (f.msgType == 6) {
            f.fileUrl = f.content.split(",")[0];
            f.fileName = f.content.split(",")[1];
            //   获取最后一个"."之后的内容
            let index = f.fileUrl.lastIndexOf(".");
            f.fileTypeVal = f.fileUrl.substring(index + 1, f.fileUrl.length);
          }
          
          this.list.push(f);
          this.gddb();
          e.target.value = "";
        })
        .catch(() => {
          e.target.value = "";
        });
    },
    // 选择图片 并发送
    sendImg(e) {
      if (e.target.files[0]) {
        this.compressorImage(e.target.files[0])
          .then((fl) => {
            let tload = Toast.loading({
              duration: 0,
              message: "请稍等...",
            });
            let newF = new FormData();
            newF.append("Val", "1");
            newF.append("imgAbridge  ", false);
            newF.append("file", fl);
            newF.append("fileType", 1);
            this.$http
              .request("uploadImg", newF)
              .then((res) => {
                console.log("文件上传成功", res);
                this.sendFileUrl(2, res.bigImg);
                tload.clear();
                // 获取到文件地址
                // ws 发送文件地址
                e.target.value = "";
              })
              .catch(() => {
                tload.clear();
                e.target.value = "";
              });
          })
          .catch(() => {});
      }
    },
    // 拍摄照片进行上传
    sendImg_ps(f) {
      Toast("请稍等");
      let newF = new FormData();
      newF.append("Val ", "1");
      newF.append("imgAbridge  ", false);
      newF.append("file", f);
      newF.append("fileType", 1);
      let tload = Toast.loading({
        duration: 0,
        message: "请稍等...",
      });
      this.$http
        .request("uploadImg", newF)
        .then((res) => {
          console.log("文件上传成功", res);
          this.sendFileUrl(2, res.bigImg);
          tload.clear();
        })
        .catch(() => {
          tload.clear();
        });
    },
    // 选择文件 并上传
    sendFile(e) {
      console.log(e.target.files[0], "上传文件222", e.target.files[0].name);
      if (e.target.files[0]) {
        Toast("请稍等");
        let newF = new FormData();
        newF.append("Val ", "0");
        newF.append("imgAbridge  ", false);
        newF.append("file", e.target.files[0]);
        newF.append("fileType", 0);
        let tload = Toast.loading({
          duration: 0,
          message: "请稍等...",
        });
        this.$http
          .request("uploadImg", newF)
          .then((res) => {
            console.log("文件上传成功", res);
            this.sendFileUrl(6, res.bigImg, e.target.files[0].name);
            tload.clear();
            // 获取到文件地址
            // ws 发送文件地址
            e.target.value = "";
          })
          .catch(() => {
            tload.clear();
          });
      }
    },
    // 上拉加载
    onRefresh() {
      // this.isLoading.refresh=false
      this.getList();
    },
    // 获取头像
    getHeadIcon(item) {
      let i = "";
      if (item.sendType == 1) {
        i = item.accountId;
      } else if (item.sendType == 2) {
        i = item.seeDoctorId;
      }
      // console.log(this.userObj,i,"Z_"+item.sendType+"_"+i,item)
      try {
        // return this.userObj["Z_"+item.sendType+"_"+i].headImg|| yizhuImg
        if (item.sendType == 1) {
          return this.userObj["Z_1_" + item.accountId].headImg || yizhuImg;
        } else if (item.sendType == 2) {
          return this.userObj["Z_2_" + item.seeDoctorId].headImg || huanzheImg;
        }
      } catch {
        return "";
      }
    },
    // 获取称呼
    getName(item) {
      let i = "";
      if (item.sendType == 1) {
        i = item.accountId;
      } else if (item.sendType == 2) {
        i = item.seeDoctorId;
      }
      try {
        return this.userObj["Z_" + item.sendType + "_" + i].memberName || "";
      } catch {
        return "";
      }
    },
    // 标记为已读
    setPatientCurrSessionRead() {
      // 接口调用
      this.$http
        .request("setPatientMsgRead", {
          roomId: this.$route.query.id,
          patientId: this.$cache.local.getItem("patientId"),
        })
        .then(() => {})
        .catch(() => {});
    },
    // 显示视频、语音
    showSpYy(t) {
      if (t == "sp") {
        if (this.isShow.yy) {
          Toast("请先结束语音通话");
          return;
        }
        this.isShow.spView = true;
        this.isShow.sp = true;
      } else if (t == "yy") {
        if (this.isShow.sp) {
          Toast("请先结束视频通话");
          return;
        }
        this.isShow.yyView = true;
        this.isShow.yy = true;
      }
    },
    gddb() {
      this.$nextTick(() => {
        console.log("滚动到最下方");
        if (this.list.length > 0) {
          let sh = this.$refs.listView.$el.offsetHeight;
          let lh = this.$refs.list.offsetHeight;
          if (lh > sh) {
            this.$refs.listView.$el.scrollTop = lh - sh + 10;
            console.log(lh, sh, lh - sh);
          }
        } else {
          console.error("空");
        }
      });
    },
    // 预览图片
    showbigImg(imgList) {
      ImagePreview(imgList);
    },

    /*
	@param {type} t 1语音开启 2视频开启 3语音关闭 4视频关闭
	 */
    sendVARoomState(t) {
      console.error("语音视频状态", t);
      let f = {
        msgType: "MEDIA DATA",
        data: {
          roomId: this.$route.query.id,
          patientId: this.$cache.local.getItem("patientId"),
          content: "",
          callStatus: t,
          sendType: 2,
          accountId: this.$route.query.accountId,
        },
      };

      this.$myws.send(JSON.stringify(f));
    },
    // 设置聊天室人员聊天状态
    setMediaState(item) {
      if (item.callStatus == 1) {
        this.mediaStateList["S1_" + item.accountId] = new Date().getTime();
      } else if (item.callStatus == 2) {
        this.mediaStateList["S2_" + item.accountId] = new Date().getTime();
      } else if (item.callStatus == 3) {
        this.mediaStateList["S1_" + item.accountId] = 0;
      } else if (item.callStatus == 4) {
        this.mediaStateList["S2_" + item.accountId] = 0;
      }
      this.getMediaState();
    },
    // 获取连接语音视频个数 语音/视频 只存在一个
    getMediaState() {
      let n = new Date().getTime();
      let topObj = null;
      for (let i in this.mediaStateList) {
        if (n - this.mediaStateList[i] <= 12000) {
          topObj = {};
          if (i.indexOf("S1") == 0) {
            topObj.type = 1;
          } else if (i.indexOf("S2") == 0) {
            topObj.type = 2;
          }
          break;
        }
      }
      console.error("执行", topObj);
      this.topObj = topObj;
    },
    showJzrInfo() {
      this.isShow.hzInfo = true;
    },
    // 获取用户列表 --所有
    getuserList(num, t) {
      this.$http
        .request("getRoomUserList", { roomId: this.$route.query.id })
        .then((res) => {
          let userObj = this.userObj;
          for (let i in res) {
            userObj["Z_" + res[i].sendType + "_" + res[i].id] = res[i];
          }
		  if(t=="getUserList"){
			  return;
		  }
          // this.userObj = userObj;
          this.getList(num || 20, t);
        })
        .catch(() => {});
    },
    // 复制文字
    copyText(v) {
      let input = document.createElement("input");
      input.value = v;
      //添加input标签
      document.body.appendChild(input);
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
      //移除input标签
      document.body.removeChild(input);
      Toast("复制成功,请在第三方浏览器中打开");
    },
	// 切换更多显示、隐藏
	changeElseFeature(){
		this.isShow.elseFeature = !this.isShow.elseFeature;
			this.$nextTick(()=>{
				this.gddb()
			})
		
	},
	// 下载文件
	loadFile(fileUrl,fileName){
		this.$router.push({
			path:"/downFile",
			query:{
				fileUrl:fileUrl,
				fileName:fileName,
			}
		})
	},
	hideElseFeature(){
		this.isShow.elseFeature = false;
		window.setTimeout(()=>{
			this.gddb()
		},200)
	},
  },
  beforeUnmount() {
    console.log("销毁");
	if(this.roomId){
		this.$cache.setRoomInputValue(this.roomId,this.inputText)
	}
    if (this.getMediaStateDs) {
      window.clearInterval(this.getMediaStateDs);
    }
    if (this.fixedTopDs) {
      window.clearInterval(this.fixedTopDs);
    }
  },
  beforeRouteLeave(to,from,next){
	  let showText=""
	  if(this.isShow.sp||this.isShow.yy){
		  if(this.isShow.sp){
			  showText="是否确认退出视频会诊？"
		  }else if(this.isShow.yy){
			  showText="是否确认退出语音会诊？"
		  }
		  Dialog.confirm({
		    title: '提示',
		     closeOnPopstate: false,
		    message:showText,
		  })
		    .then((res) => {
				try{
					this.$refs.sp.recClose();
					this.$refs.yy.recClose();
				}catch{}
		  		 next();
		    })
		    .catch((e) => {
		  			next(false)
		    });
	  }else{
		  next()
	  }
	
  },
  
};
</script>
<style scoped lang="scss">
.ltsPage{
	display: flex;
	height: 100vh;
	flex-direction:column;
}
.view {
  width: 100%;
  flex-shrink:1;
  height: 100%;
  background-color: #f4f4f4;
  box-sizing: border-box;
  padding-bottom: 10px;
  overflow: auto;
}
.list {
  overflow: hidden;
}
.item {
  // margin-top: 22px;
  padding-top: 22px;
  width: 100%;
}
.tsText {
  font-size: 13px;
  color: #999999;
  line-height: 26px;
  text-align: center;
}
.my {
  display: flex;
  justify-content: end;
  justify-content: flex-end;
  width: 100%;
  .text {
    border-radius: 8px 0 8px 8px;
    float: right;
    &.text-bg {
      background-color: #278eff;
      color: #fff;
    }
  }
  .userName {
    text-align: right;
    float: right;
  }
  .createTime {
    float: right;
  }
}
.you {
  width: 100%;
  display: flex;
  justify-content: start;
  .text {
    float: left;
    border-radius: 0 8px 8px 8px;
  }
  .userName {
    float: left;
  }
}
.textAndName {
  width: calc(100% - 130px);
  overflow: hidden;
}
.createTime {
  color: #5d6e7f;
  font-size: 12px;
}
.userName {
  width: 100%;
  margin: 4px 0;
  color: #5d6e7f;
  font-size: 13px;
}
.text {
  background-color: #fff;
  padding: 8px;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  color: #27393b;
  word-break: break-all;
}
.headIcon {
  width: 56px;
  height: 56px;
  margin: 0 7px;
  > img {
    width: 100%;
    height: 100%;
  }
}
.wsImg {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.bottomView {
	flex-shrink:0;
  // height: 89px;
  border-top: 1px solid #eee;
  background-color: #f4f4f4;
  // overflow: auto;
  .bottomView-tob2 {
    .inputText {}
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.elseFeature {
  overflow: hidden;
  margin-left: 0.27rem;
  margin-top: 10px;
  min-height: 190px;
  .elseFeature-item {
    width: 1.28rem;
    margin: 10px 0.23rem 0;
    text-align: center;
    float: left;
    img {
      width: 1.28rem;
      height: 1.28rem;
    }
    p {
      margin-top: 2px;
      color: #5d6e7f;
    }
  }
}
// 医生推荐
.toDoctors-view {
  width: 200px;
  .toDoctors {
    padding: 0 6px;
    background: #ffffff;
    border-radius: 0px 8px 8px 8px;
    .toDoctors-title {
      font-size: 15px;
      font-weight: 500;
      color: #272d3b;
      line-height: 30px;
    }
    .toDoctors-b {
      display: flex;
      justify-content: start;
      .toDoctors-b-img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .toDoctors-b-name {
        font-size: 15px;
        font-weight: 500;
        color: #272d3b;
        line-height: 22px;
      }
      .toDoctors-b-text {
        font-size: 13px;
        font-weight: 400;
        color: #272d3b;
        line-height: 20px;
      }
    }
    .toDoctors-c {
      padding: 7px 0 11px;
      .toDoctors-c-text {
        font-size: 12px;
        font-weight: 400;
        background-color: #eaf4ff;
        color: #278eff;
        line-height: 17px;
        padding: 2px 4px;
      }
    }
    .toDoctors-d {
      border-top: 1px solid #eeeeee;
      padding-top: 10px;
      text-align: center;
      > span {
        font-size: 14px;
        font-weight: 400;
        color: #278eff;
      }
    }
  }
}
// 待支付
.unpaid-view {
  width: 200px;
}
.item-view7,
.item-view8 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .view-z {
    width: 200px;
    padding: 10px;
    background: #ffffff;
    border-radius: 0px 8px 8px 8px;
  }
  .item-title {
    font-size: 15px;
    font-weight: 500;
    color: #272d3b;
    line-height: 30px;
  }
  .item-bottom {
    margin-top: 6px;
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    text-align: center;
    color: #278eff;
  }
}
.fk {
  position: fixed;
  width: 100px;
  right: 0px;
  bottom: 100px;
  z-index: 1001;
  > div {
    height: 40px;
    line-height: 40px;
    color: #00a8ff;
    background-color: #fff;
    text-align: center;
    margin: 10px;
    box-shadow: 0 0 4px #ccc;
  }
}
.download-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  height: 80px;
  .file-name {
    span {
		color: #00a8ff;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-decoration: underline;
      font-style: italic;
      white-space: pre-wrap;
    }
  }
  img {
    width: 50px;
    height: 50px;
  }
}

.bot-msg {
  width: 100%;
  // background-color: #e8f3ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 15px;
  padding: 0 15px;
  box-sizing: border-box;
  // border-radius: 8px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  bottom: 0;
  color: #fff;
  span {
    display: flex;
    align-items: center;
  }
}
.bot-msg-vol1 {
  background-color: rgba($color: #2ac2a8, $alpha: 0.7);
}
.bot-msg-vol2 {
  background-color: rgba($color: #f79c42, $alpha: 0.7);
}
.dw-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  // .hzItem{}
}
.inputText{
	align-items:flex-end;
	align-items:end;
	background:transparent;
	padding-left: 6;
	::v-deep(textarea){
		line-height: 30px;
		background: #fff;
		border-radius: 4px;
		padding: 4px 8px;
	}
}
</style>
